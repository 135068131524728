import React from "react";
import { useLocation } from "react-router-dom";
import {
  Paper,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@mui/material";
import styled from "styled-components";

import PageNotFound from "../errors/NotFound";

const PostContainer = styled(Paper)`
  margin-bottom: 20px;
  background-color: #fff !important;
`;

const PostContent = styled(CardContent)`
  display: flex;
  align-items: center;
`;

const PostText = styled(Typography)`
  flex: 1;
`;

export default function ThoughtsPost() {
  const location = useLocation();
  const { data } = location.state;

  if (!data) {
    return <PageNotFound />;
  }

  return (
    <Container
      maxWidth="md"
      sx={{ mt: 4, paddingTop: 8, paddingBottom: "68px" }}
    >
      <Typography variant="h5" component="div">
        {data.title}
      </Typography>
      <CardMedia
        sx={{
          backgroundPosition: "center bottom",
          height: 486,
          mb: 2,
        }}
        image={require(`../../../assets/journal/${data.image}`)}
        alt={data.title}
      />
      {console.log(data.title)}
      <PostContainer elevation={0} key={data.id}>
        <PostContent>
          <PostText variant="h4">{data.content}</PostText>
        </PostContent>
      </PostContainer>
    </Container>
  );
}
