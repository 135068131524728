import React from "react";
import { Container, Typography, useMediaQuery } from "@mui/material";
import styled, { keyframes } from "styled-components";

import tumbleweedSvg from "../../../assets/page-not-found/tumbleweed.svg";
import cactusSvg from "../../../assets/page-not-found/cactus.svg";

const NotFoundContainer = styled(Container)`
  text-align: center;
  padding: 40px;
  position: relative;
  overflow: hidden;
`;

const Tumbleweed = styled.img`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  position: absolute;
  left: -100%;
  top: 25%;
  transform: translate(-50%, -50%);
  z-index: 1;

  /* Animation to spin and move the sphere @codepen.io/naveenmaurya */
  -webkit-animation: spin 1400ms linear infinite,
    moveLeftToRight 5s linear infinite;
  animation: spin 1400ms linear infinite, moveLeftToRight 5s linear infinite;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;

  /* Spinning the sphere using key frames */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  /* Move sphere from left to right */
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
    100% {
      left: 100%;
    }
  }
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
    100% {
      left: 100%;
    }
  }
`;

const Cactus = styled.img`
  width: 115px;
  height: 250px;
  position: relative;
  right: 655px;
  top: 70px;

  @media (max-width: 1024px) {
    right: 350px;
  }

  @media (max-width: 768px) {
    right: 250px;
  }
`;

const flyRightOne = keyframes`
  0% {
    left: -10%;
    transform: scale(0.3);
  }
  10% {
    left: 10%;
    transform: translateY(2vh) scale(0.4);
  }
  20% {
    left: 30%;
    transform: translateY(0vh) scale(0.5);
  }
  30% {
    left: 50%;
    transform: translateY(4vh) scale(0.6);
  }
  40% {
    left: 70%;
    transform: translateY(2vh) scale(0.6);
  }
  50% {
    left: 90%;
    transform: translateY(0vh) scale(0.6);
  }
  60% {
    left: 110%;
    transform: translateY(0vh) scale(0.6);
  }
  100% {
    left: 110%;
    opacity: 1;
    transform: translateY(0vh) scale(0.6);
  }
`;

const flyCycle = keyframes`
  100% {
    background-position: -900px 0;
  }
`;

const BirdContainer = styled.div`
  position: absolute;
  bottom: 73%;
  left: -7.5vw;
  z-index: 1;
  transform: scale(0);
  will-change: transform;
  animation-name: ${flyRightOne};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const Bird = styled.div`
  background-image: url("http://www.iamramraj.com/demo/Flying_birds_CSS_animation_examples/bird-cells.svg");
  background-size: auto 100%;
  width: 80px;
  height: 123px;
  will-change: background-position;
  animation-name: ${flyCycle};
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: -0.5s;
`;

const BirdBehind = styled(Bird)`
  background-image: url("http://www.iamramraj.com/demo/Flying_birds_CSS_animation_examples/bird-cells.svg");
  background-size: auto 100%;
  width: 80px;
  height: 125px;
  position: absolute;
  top: 50%;
  left: -20px;

  transform: translate(-50%, -50%);
  will-change: background-position;
  animation-name: ${flyCycle};
  animation-timing-function: steps(10);
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-delay: -0.5s;
`;

const BirdContainerOne = styled(BirdContainer)`
  animation-duration: 15s;
  animation-delay: 0;
`;

const HorizontalLine = styled.div`
  height: 2px;
  background-color: #49877b;
`;

const GrassBladeContainer = styled.div`
  position: relative;
`;

const swayAnimation = keyframes`
  0% {
    transform: scaleY(1) rotateZ(0deg);
  }
  50% {
    transform: scaleY(1.1) rotateZ(-5deg);
  }
  100% {
    transform: scaleY(1) rotateZ(0deg);
  }
`;

const GrassBlade = styled.div`
  border-right: 3px solid #49877b;
  border-top-right-radius: 95%;
  position: absolute;
  width: 2px;
  height: 15px;
  transform: translate(-50%, 0%) translate3d(0, 0, 0);
  animation: ${swayAnimation} 2s infinite;

  &.blade--1 {
    animation-delay: 0.1s;
  }

  &.blade--2 {
    animation-delay: 0.2s;
  }

  &.blade--3 {
    animation-delay: 0.3s;
  }
`;

export default function PageNotFound() {
    const isSmallScreen = useMediaQuery("(max-width:600px)");

    const numGrassBlades = 30;

    const grassBlades = Array.from({ length: numGrassBlades }).map((_, index) => {
        const left = Math.floor(Math.random() * 100) + "%";
        const top = Math.floor(Math.random() * 20) + "px";

        return (
            <GrassBladeContainer key={index}>
                <GrassBlade style={{ left, top }} />
            </GrassBladeContainer>
        );
    });

    return (
        <NotFoundContainer maxWidth="xl">
            {!isSmallScreen && (
                <BirdContainerOne>
                    <Bird />
                    <Bird />
                    <BirdBehind />
                </BirdContainerOne>
            )}
            {!isSmallScreen && <Tumbleweed src={tumbleweedSvg} alt="Tumbleweed" />}
            {!isSmallScreen && <Cactus src={cactusSvg} alt="Cactus" />}
            {!isSmallScreen && <HorizontalLine>{grassBlades}</HorizontalLine>}
            <Typography
                variant="h1"
                component="h1"
                sx={{ fontSize: isSmallScreen ? 100 : 200, mb: 2 }}
            >
                404
            </Typography>
            <Typography variant="h4" component="h1" sx={{ mt: 4, mb: 2 }}>
                Oops! Page Not Found
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                The requested page could not be found. Please check the URL or go back
                to the homepage.
            </Typography>
        </NotFoundContainer>
    );
}
