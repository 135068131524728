import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import {
  Box,
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  LinearProgress,
} from "@mui/material";
import { Typography, Button, Link } from "@mui/material";

import AnimatedBackground from "../../layout/AnimatedBackground";
import { skillsData } from "../../../data/skillsData";
import { allWorkData } from "../../../data/portfolioData";

const HeroSection = styled("section")({
  minHeight: "57vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff",
  padding: "32px",
  "@media (max-width: 600px)": {
    minHeight: "63vh",
  },
});

const HeroContent = styled("div")({
  textAlign: "center",
});

const HeroTitle = styled(Typography)({
  fontWeight: "bold",
  fontSize: "2.5rem",
  marginBottom: "16px",
  "@media (max-width: 600px)": {
    fontSize: "2rem",
  },
  "@media (max-width: 400px)": {
    fontSize: "1.5rem",
  },
});

const HeroSubtitle = styled(Typography)({
  marginBottom: "32px",
  "@media (max-width: 600px)": {
    fontSize: "1.5rem",
  },
  "@media (max-width: 400px)": {
    fontSize: "1rem",
  },
});

const ProjectsSection = styled("section")({
  padding: "48px 32px",
  background: "#f5f5f5",
  "@media (max-width: 600px)": {
    padding: "10px",
  },
});

const ProjectsTitle = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "32px",
});

const ProjectCard = styled(Card)({
  backgroundColor: "#fff !important",
  maxWidth: "253px",
  minHeight: "350px",
  margin: "16px",
  padding: "16px",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  "@media (max-width: 1024px)": {
    maxWidth: "247px",
  },
  "@media (max-width: 960px)": {
    maxWidth: "270px",
  },
  "@media (max-width: 600px)": {
    maxWidth: "280px",
  },
  "@media (max-width: 400px)": {
    maxWidth: "235px",
  },
  "@media (max-width: 320px)": {
    maxWidth: "194px",
  },
});

const ProjectCardMedia = styled(CardMedia)({
  height: "200px",
});

const ProjectTitle = styled(Typography)({
  fontWeight: "bold",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
});

const ProjectDescription = styled(Typography)({
  maxHeight: "100px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",

  "@media (max-width:600px), (max-width:900px)": {
    WebkitLineClamp: 2,
  },
});

const CarouselContainer = styled(Grid)({
  position: "relative",
});

const SkillsSection = styled("section")({
  padding: "48px 32px",
});

const SkillsTitle = styled(Typography)({
  fontWeight: "bold",
  marginBottom: "32px",
});

const SkillCategoryIcon = styled("div")({
  marginTop: "32px",
  marginBottom: "8px",
});

const ProgressBar = styled(LinearProgress)`
  && {
    background-color: #9ee8da;
  }
  .MuiLinearProgress-bar {
    background-color: #00c49f;
  }
`;

export default function Home() {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handlePrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const navigate = useNavigate();

  const openPost = (data) => {
    navigate(`/work/${data.title}`, { state: { data } });
  };

  return (
    <div>
      <AnimatedBackground />
      <HeroSection>
        <Container maxWidth="md">
          <HeroContent>
            <HeroTitle variant="h1" component="h1">
              Hi! Welcome to Verdant
            </HeroTitle>
            <HeroSubtitle variant="h5" component="h2" mb={4}>
              I'm Ghyorka, and this is my digital haven – Verdant. The name
              "Verdant" is inspired by the Latin word "viridis," meaning green
              and flourishing. It represents growth, abundance, and vitality,
              which I showcase through my work here.
            </HeroSubtitle>

            <Button
              href="/work"
              variant="contained"
              color="primary"
              size="large"
            >
              View Work
            </Button>
          </HeroContent>
        </Container>
      </HeroSection>

      <ProjectsSection>
        <Container maxWidth="md">
          <ProjectsTitle variant="h4" component="h2">
            Featured Projects
          </ProjectsTitle>
          <CarouselContainer>
            <Slider {...settings} ref={sliderRef}>
              {allWorkData.slice(0, 5).map((project) => (
                <ProjectCard key={project.id}>
                  <CardActionArea onClick={() => openPost(project)}>
                    <ProjectCardMedia
                      component="img"
                      alt={project.title}
                      image={require(`../../../assets/${project.image}`)}
                      title={project.title}
                    />
                    <CardContent>
                      <ProjectTitle gutterBottom variant="h5" component="h2">
                        {project.title}
                      </ProjectTitle>
                      <ProjectDescription
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {project.creativeApproach}
                      </ProjectDescription>
                    </CardContent>
                  </CardActionArea>
                </ProjectCard>
              ))}
            </Slider>
            <Button onClick={handlePrevSlide}>
              <span>&lt;</span>
            </Button>
            <Button onClick={handleNextSlide}>
              <span>&gt;</span>
            </Button>
          </CarouselContainer>
        </Container>
      </ProjectsSection>

      <SkillsSection>
        <Container maxWidth="md">
          <Link href="/skills" underline="none">
            <SkillsTitle variant="h4" component="h2">
              Skills
            </SkillsTitle>
          </Link>

          {skillsData.map((category, categoryIndex) => (
            <div key={category.category}>
              {categoryIndex !== 0 && <Box mt={6} />}

              <SkillCategoryIcon>{category.icon}</SkillCategoryIcon>
              <Grid container spacing={2}>
                {category.skills.map((skill, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Typography variant="body1">{skill.name}</Typography>
                    <Box width="100%" mb={1}>
                      <ProgressBar
                        variant="determinate"
                        value={skill.level === "Expert" ? 100 : 70}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </div>
          ))}
        </Container>
      </SkillsSection>
    </div>
  );
}
