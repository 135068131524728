import { createTheme } from "@mui/material/styles";

// custom theme
const theme = createTheme({
  typography: {
    fontFamily: ["Monospace", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "3.5rem",
      fontWeight: "bold",
      marginBottom: "2rem",
      color: "#000000",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: "bold",
      marginBottom: "0.75rem",
      color: "#000000",
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "1rem",
      color: "#FFFFFF",
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "1.75rem",
      color: "#000000",
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: "bold",
      marginBottom: "0.5rem",
      color: "#000000",
    },
    h6: {
      fontSize: "1.25rem",
      fontWeight: "bold",
      marginBottom: "0.25rem",
      color: "#000000",
    },
    body1: {
      fontSize: "1rem",
      marginBottom: "0.5rem",
      color: "#000000",
    },
  },
  palette: {
    common: {
      white: "#FFFFFF",
    },
    grey: {
      100: "#F5F5F5",
    },
    text: {
      primary: "#000000",
    },
    primary: {
      main: "#00C49F",
    },
    secondary: {
      main: "#6A0DAD",
    },
    background: {
      default: "#111111",
      paper: "#1E1E1E",
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  ],
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "80px",
        },
      },
    },
  },
});

export default theme;
