import React from "react";
import {
  Typography,
  Grid,
  Box,
  LinearProgress,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import styled from "styled-components";

import { technicalSkills } from "./TechnicalSkills";
import { learningGoals } from "./LearningGoals";

const Container = styled.div`
  padding: 16px;
`;

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 16px;
`;

const SkillItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const SkillIcon = styled.div`
  margin-right: 8px;
  color: ${({ color }) => color || "primary"};
  font-size: 30px;
`;

const SkillDetails = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const SkillProgressBar = styled(LinearProgress)`
  flex-grow: 1;
  margin-left: 16px;
`;

export default function SkillsPage() {
  return (
    <Container>
      <Card variant="outlined" style={{ backgroundColor: "#ffff" }}>
        <CardHeader title={<Title variant="h4">Technical Skills</Title>} />
        <CardContent>
          <Grid container spacing={3}>
            {technicalSkills.map((skill) => (
              <Grid item xs={12} sm={6} md={4} key={skill.name}>
                <SkillItem>
                  {skill.icon && (
                    <SkillIcon color="primary">{skill.icon}</SkillIcon>
                  )}
                  <Typography variant="h6">{skill.name}</Typography>
                  <SkillDetails>
                    <Typography variant="body2">
                      Proficiency: {skill.proficiency}%
                    </Typography>
                  </SkillDetails>
                  <SkillProgressBar
                    variant="determinate"
                    value={skill.proficiency}
                    color="primary"
                  />
                </SkillItem>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <Box mt={4}>
        <Card variant="outlined" style={{ backgroundColor: "#ffff" }}>
          <CardHeader title={<Title variant="h4">Learning Goals</Title>} />
          <CardContent>
            <Grid container spacing={3}>
              {learningGoals.map((goal) => (
                <Grid item xs={12} sm={6} md={4} key={goal.name}>
                  <SkillItem>
                    {goal.icon && (
                      <SkillIcon color="primary">{goal.icon}</SkillIcon>
                    )}
                    <Typography variant="h6">{goal.name}</Typography>
                    <SkillDetails>
                      <Typography variant="body2">
                        Progress: {goal.progress}%
                      </Typography>
                    </SkillDetails>
                    <SkillProgressBar
                      variant="determinate"
                      value={goal.progress}
                      color="primary"
                    />
                  </SkillItem>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
