import React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
  Divider,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BarChart from "./BarChart";
import profilePhoto from "../../../assets/author/design.png";
import { funFactsData, interestsData } from "../../../data/aboutData";

const RootGridContainer = styled(Grid)`
  .MuiGrid-item {
    padding: 16px;
    padding-left: 32px;
  }
`;

const CircleImageWrapper = styled(Box)`
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: none;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
  }
`;

const CircleImage = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center bottom;
`;

const InterestsContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const InterestItem = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  "& svg": {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

export default function About() {
  const isMobile = useMediaQuery("(max-width:600px) or (max-width:900px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isTabletScreen = useMediaQuery("(max-width:768px");

  return (
    <div
      style={{
        marginTop: "73px",
        "@media (max-width: 600px), (max-width: 900px)": { marginTop: "unset" },
      }}
    >
      <Container maxWidth="lg" sx={{ mb: 6 }}>
        <Typography
          variant="h1"
          align="left"
          sx={{
            fontSize: isSmallScreen ? 70 : 85,
            mb: 2,
            paddingTop: 8,
            paddingBottom: isTabletScreen && 8,
          }}
        >
          {"<whoami />"}
        </Typography>
        <RootGridContainer
          container
          spacing={2}
          sx={{
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              console.log('Hello there!')
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              <Typography
                sx={{ fontSize: 19, fontWeight: "bold" }}
                variant="body1"
                component="span"
              >
                {"<p>"}
              </Typography>
              I'm Ghyorka, a passionate coder and ethical hacker from Nigeria
              now based in the Netherlands. I take pride in my never-ending zeal
              to learn new concepts and tools to expand my knowledge and bridge
              any gaps that may exist. Call me an avid learner!
              <Typography
                sx={{ fontSize: 19, fontWeight: "bold" }}
                variant="body1"
                component="span"
              >
                {"</p>"}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ display: "flex", justifyContent: "center", paddingTop: 0 }}
          >
            <CircleImageWrapper component="div">
              <CircleImage src={profilePhoto} alt="profile" />
            </CircleImageWrapper>
          </Grid>
        </RootGridContainer>
      </Container>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Developer || Coder
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              I have a deep love for problem-solving. I believe in the power of
              technology to transform the world and improve people's lives. I
              specialize in various programming languages, such as Python,
              JavaScript, and TypeScript, and enjoy working on both front-end
              and back-end development projects.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              I thrive on challenges that push my technical skills to the limit,
              and I am constantly exploring new technologies, frameworks, and
              tools to stay up-to-date with the ever-evolving tech landscape.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {"isEthical ? 'Hacker' : 'undefined'"}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              As an ethical hacker, I am passionate about cybersecurity and
              ensuring the security and integrity of digital systems. I have
              expertise in penetration testing, vulnerability assessment, and
              secure coding practices.
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              I believe in using my skills for good, helping organizations
              identify and address potential security vulnerabilities, and
              promoting a secure digital environment.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ mt: 2, mb: 2 }} />

      {!isMobile && (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <CardHeader
            sx={{ paddingLeft: 0 }}
            title={
              <Typography variant="h4" fontWeight={"bold"} spacing={3}>
                Learning Goals
              </Typography>
            }
          />
          <BarChart />
        </Container>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Container maxWidth="lg" sx={{ mt: 4, paddingTop: "30px" }}>
        <Grid container direction="row-reverse" alignItems="flex-start">
          <Grid item xs={12} sm={6}>
            <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
              Fun Facts
            </Typography>
            {funFactsData.map((fact, index) => (
              <Box
                display="flex"
                alignItems="center"
                key={index}
                sx={{ mb: 2 }}
              >
                <Typography variant="body1">
                  {fact.fact}
                  <span role="img" aria-label={fact.label}>
                    {fact.emoji}
                  </span>
                </Typography>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ mt: 2, mb: 2 }} />

      <Container
        maxWidth="lg"
        sx={{ mt: 4, paddingTop: "30px", paddingBottom: "100px" }}
      >
        <InterestsContainer item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
            Interests
          </Typography>
          {interestsData.map((interest, index) => (
            <InterestItem key={index}>
              <FontAwesomeIcon icon={interest.icon} size="lg" />
              <div>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {interest.title}
                </Typography>
                <Typography variant="body1">{interest.description}</Typography>
              </div>
            </InterestItem>
          ))}
        </InterestsContainer>
      </Container>
    </div>
  );
}
