import React from "react";
import { Grid, IconButton } from "@material-ui/core";
import { Link, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background-color: #eeeeee;
  padding: 32px;
  margin-top: auto;
  position: static;
  bottom: 0;
`;

const Title = styled(Typography)`
  font-weight: bold;
  margin-bottom: 16px;
`;

const StyledIconButton = styled(IconButton)`
  margin-right: 16px;
  color: #eeeeee;

  &:hover {
    color: #00c49f;
    background-color: transparent;
  }
`;

const SocialIconsContainer = styled(Grid)`
  margin-bottom: 16px;
`;

function Footer() {
  return (
    <FooterContainer>
      <Link href="/" underline="none">
        <Title variant="h6" component="h2">
          Verdant
        </Title>
      </Link>
      <Typography variant="body1" component="p">
        &copy; {new Date().getFullYear()} Ghyorka Kpee. All rights reserved.
      </Typography>
      <SocialIconsContainer container alignItems="center">
        <Grid item>
          <StyledIconButton
            href="https://www.instagram.com/cryupsoul/"
            target="_blank"
            rel="noopener"
            aria-label="Facebook"
          >
            <InstagramIcon />
          </StyledIconButton>
        </Grid>
        <Grid item>
          <StyledIconButton
            href="https://github.com/ggg6r34t"
            target="_blank"
            rel="noopener"
            aria-label="Twitter"
          >
            <GitHubIcon />
          </StyledIconButton>
        </Grid>
        <Grid item>
          <StyledIconButton
            href="https://www.linkedin.com/in/ghyorkakpee"
            target="_blank"
            rel="noopener"
            aria-label="LinkedIn"
          >
            <LinkedInIcon />
          </StyledIconButton>
        </Grid>
      </SocialIconsContainer>
    </FooterContainer>
  );
}

export default Footer;
