export const recentWorkData = [
  {
    id: 1,
    image: "portfolio/script-execution-process.png",
    title: "Automated Backup Script",
    description: [
      `This ingenious script meticulously crafted using the power of Bash Shell scripting empowers users to effortlessly safeguard their critical files
       and directories, ushering in a new era of efficient backup management. 
      With an arsenal of advanced features, including encryption, compression, remote backups, and integration with cloud storage services, 
      this script is poised to revolutionize the way you approach data backup.`,
    ],
    creativeApproach: `The world of data backup is characterized by a commitment to simplicity and efficiency. 
    By harnessing the versatility of Bash Shell scripting, I engineered a solution that automates a traditionally cumbersome task. 
    Beyond just automating backups, the script introduces innovative elements like encryption and remote backup integration, 
    providing users with an unparalleled level of control over their data.`,
    tools: ["Compression utilities", "cloud storage APIs", "Remote servers"],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "By encapsulating complex backup procedures within a user-friendly script, users can initiate backups effortlessly, thereby reducing the potential for human error.",
      "The script's integration of encryption ensures that sensitive data remains secure, while compression optimizes storage space, making it ideal for managing large datasets.",
      "The script's capacity to perform remote backups empowers users to maintain data redundancy across different locations, adding a layer of data resilience.",
      "Seamless integration with cloud storage services enables users to directly store backups in cloud repositories, enhancing accessibility and disaster recovery options.",
      "The script's built-in logging functionality provides a clear record of backup activities, facilitating oversight and troubleshooting.",
    ],
    lessonsLearned: `Throughout the development of this script, I realized the importance of striking a balance between automation and customization. 
    While automation streamlines the backup process, tailoring the script to accommodate diverse user needs ensures that it remains a versatile and adaptable solution.`,
    conclusion: [
      `In conclusion, the Automated Backup Script represents a fusion of technological excellence and user-centric design. 
      By harnessing the power of Bash Shell scripting, I have engineered a solution that not only automates the backup process 
      but also introduces advanced features like encryption, compression, remote backups, and cloud storage integration. 
      Reflecting on this endeavour, it's clear that the marriage of technology and innovation has resulted in a tool 
      that empowers users to safeguard their data with unprecedented ease and efficiency.`,
      `The Automated Backup Script is not just a utilitarian tool; it's a testament to my commitment to simplifying complex tasks and enhancing data security. 
      As the digital landscape evolves, this script stands as a beacon of adaptability, resilience, 
      and innovation—a solution designed to safeguard your data in an increasingly interconnected and data-driven world.`,
    ],
  },
  {
    id: 2,
    image: "portfolio/landing_page_shoe_ecommerce_website.png",
    title: "ShoeShop: Full-Stack eCommerce Website",
    description: [
      `As the final chapter of my Full-stack Developer journey at Integrify Academy, I embarked on my graduation project, a Full-stack Shoe eCommerce website. 
      This project exemplifies the culmination of my full-stack developer journey, bringing together theory and practice to solve real-world challenges. 
      Through meticulous coding, problem-solving, and collaborative spirit, I have achieved a project that not only reflects my technical prowess but also demonstrates my ability to create a user-centric digital solution. 
      Throughout the development process, I focused on creating an intuitive and seamless user experience.`,
      `The frontend showcases my design finesse, offering users an elegant and intuitive interface to explore an extensive range of footwear. 
      Through dynamic product displays, interactive filters, and a captivating visual experience, I have ensured that users can effortlessly navigate and engage with our curated collection. 
      The backend expertise shines through in the implementation of intricate features, such as real-time inventory management, secure payment gateways, and seamless user authentication. 
      The synergy between frontend aesthetics and backend functionality manifests in a project that not only reflects my technical proficiency but also my ability to cater to user needs. 
      The Full Stack Shoe eCommerce website stands as a testament to my dedication, perseverance, adaptability, and growth during my time at Integrify Academy, equipping me with the tools to forge a path of innovation in the ever-evolving landscape of web development.`,
    ],
    creativeApproach: `In addition to the technical aspects, I infused the project with a touch of creativity to elevate the user experience. 
        From captivating product displays and intuitive navigation to engaging visuals and responsive design, 
        I aimed to create an immersive and memorable shopping environment. 
        I carefully considered user behavior and incorporated user-centric design principles to ensure ease of use and intuitive interaction.`,
    technologies: [
      {
        backend: [
          "Express.js",
          "Node.js",
          "Bcrypt",
          "CORS",
          "Passport.js",
          "Mongoose (for MongoDB interactions)",
        ],
        frontend: ["React.js", "TypeScript", "Redux Toolkit", "Material-UI"],
      },
    ],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Implemented a visually appealing and user-friendly interface, fostering an enjoyable shopping experience for customers.",
      "Integrated secure payment gateways, ensuring the protection of sensitive customer information.",
      "Optimized website performance for efficient loading and smooth navigation across various devices and browsers.",
      "Implemented effective inventory management and order processing systems for streamlined operations",
    ],
    lessonsLearned: `This project provided invaluable lessons and growth opportunities. 
    It allowed me to refine my skills in frontend development, user experience design, and project management. 
    I gained a deeper understanding of the complexities involved in eCommerce websites and developed strategies to overcome challenges effectively.`,
    conclusion: [
      `This eCommerce website project is a testament to my dedication to delivering outstanding digital experiences. 
    By leveraging innovative technologies and a creative approach, I successfully crafted a user-centric and visually appealing online store. 
    I am proud of the outcome and look forward to taking on new projects that push boundaries and exceed expectations.`,
    ],
  },
  {
    id: 5,
    image: "portfolio/question-mark.png",
    title: "OWASP Top 10 SecureCoder App",
    description: [
      `SecureCoder is application designed to empower developers with the knowledge and tools they need to create robust and secure software. 
      In a world where cyber threats are ever-evolving, ensuring the security of software is paramount. SecureCoder not only educates developers on secure coding practices 
      but also provides hands-on tools to detect and prevent vulnerabilities. This innovative application harnessing the power of Python, regular expressions (regex), 
      and an acute awareness of the OWASP Top 10 vulnerabilities. `,
    ],
    creativeApproach: `The idea behind SecureCoder is to make secure coding practices accessible and engaging. 
    The concept of security can sometimes be a daunting topic, especially for developers new to the field. 
    Therefore, with a combination of education and practicality. 
    SecureCoder offers a comprehensive guide to secure coding, explaining the importance of each practice and providing real-world examples.`,
    tools: ["Python", "Regular Expressions (Regex)", "OWASP Top 10"],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Successfully detects and prevents numerous vulnerabilities, safeguarding applications from potential threats.",
      "Automation capabilities to streamline the process of identifying and addressing vulnerabilities..",
      "Identifies vulnerabilities related to the OWASP Top 10, including issues like SQL injection, cross-site scripting (XSS), and security misconfigurations.",
      "Seamlessly integrates into existing development workflows, ensuring minimal disruption to the coding process. ",
    ],
    lessonsLearned: `In the realm of software security, the adage "Prevention Over Remediation" stands as a guiding principle. 
    This emphasizes that prioritizing security during the coding phase is significantly more efficient and cost-effective than attempting to address vulnerabilities after production. 
    Moreover, SecureCoder underscores the pivotal role of education in security. It's not merely about the tools; it's about comprehending and embracing secure coding practices. 
    SecureCoder's educational facet has illuminated the profound importance of nurturing security expertise within development teams, empowering them to proactively protect their code and applications.`,
    conclusion: [
      `The SecureCoder app represents a transformative approach to software security. 
      By harnessing the capabilities of Python, regular expressions, and the knowledge of the OWASP Top 10 vulnerabilities, it equips developers with a powerful tool to fortify their code against threats.`,
    ],
  },
];

// create featuredProjectData once project data has been entered.

export const allWorkData = [
  {
    id: 1,
    image: "portfolio/landing_page_shoe_ecommerce_website.png",
    title: "ShoeShop: Full-Stack eCommerce Website",
    description: [
      `As the final chapter of my Full-stack Developer journey at Integrify Academy, I embarked on my graduation project, a Full-stack Shoe eCommerce website. 
      This project exemplifies the culmination of my full-stack developer journey, bringing together theory and practice to solve real-world challenges. 
      Through meticulous coding, problem-solving, and collaborative spirit, I have achieved a project that not only reflects my technical prowess but also demonstrates my ability to create a user-centric digital solution. 
      Throughout the development process, I focused on creating an intuitive and seamless user experience.`,
      `The frontend showcases my design finesse, offering users an elegant and intuitive interface to explore an extensive range of footwear. 
      Through dynamic product displays, interactive filters, and a captivating visual experience, I have ensured that users can effortlessly navigate and engage with our curated collection. 
      The backend expertise shines through in the implementation of intricate features, such as real-time inventory management, secure payment gateways, and seamless user authentication. 
      The synergy between frontend aesthetics and backend functionality manifests in a project that not only reflects my technical proficiency but also my ability to cater to user needs. 
      The Full Stack Shoe eCommerce website stands as a testament to my dedication, perseverance, adaptability, and growth during my time at Integrify Academy, equipping me with the tools to forge a path of innovation in the ever-evolving landscape of web development.`,
    ],
    creativeApproach: `In addition to the technical aspects, I infused the project with a touch of creativity to elevate the user experience. 
        From captivating product displays and intuitive navigation to engaging visuals and responsive design, 
        I aimed to create an immersive and memorable shopping environment. 
        I carefully considered user behavior and incorporated user-centric design principles to ensure ease of use and intuitive interaction.`,
    technologies: [
      {
        backend: [
          "Express.js",
          "Node.js",
          "Bcrypt",
          "CORS",
          "Passport.js",
          "Mongoose (for MongoDB interactions)",
        ],
        frontend: ["React.js", "TypeScript", "Redux Toolkit", "Material-UI"],
      },
    ],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Implemented a visually appealing and user-friendly interface, fostering an enjoyable shopping experience for customers.",
      "Integrated secure payment gateways, ensuring the protection of sensitive customer information.",
      "Optimized website performance for efficient loading and smooth navigation across various devices and browsers.",
      "Implemented effective inventory management and order processing systems for streamlined operations",
    ],
    lessonsLearned: `This project provided invaluable lessons and growth opportunities. 
    It allowed me to refine my skills in frontend development, user experience design, and project management. 
    I gained a deeper understanding of the complexities involved in eCommerce websites and developed strategies to overcome challenges effectively.`,
    conclusion: [
      `This eCommerce website project is a testament to my dedication to delivering outstanding digital experiences. 
    By leveraging innovative technologies and a creative approach, I successfully crafted a user-centric and visually appealing online store. 
    I am proud of the outcome and look forward to taking on new projects that push boundaries and exceed expectations.`,
    ],
  },
  {
    id: 2,
    image: "portfolio/authentication_sign_in_page.png",
    title: "Full-Stack Authentication App",
    description: [
      `The authentication application is forward-looking and seamlessly combines the prowess of cutting-edge 
      backend technologies with the elegance of modern frontend frameworks. 
      This project was not merely an exercise in showcasing backend skills, but rather a dynamic journey of growth and maturation, 
      where I continuously iterated upon my existing knowledge while tirelessly seeking opportunities for enhancement to align with industry best practices.`,
    ],
    creativeApproach: `At the heart of this project lies an unwavering focus on user authentication, a fundamental pillar of web security. 
    While the user sign-in/sign-up forms may appear straightforward on the frontend, 
    the backend conceals an intricate and multi-faceted system of defenses against a myriad of potential threats. 
    This creative approach goes beyond conventional security measures, delving into the realm of proactive threat anticipation and mitigation. 
    In a distinctive twist, I assumed the role of a virtual attacker, meticulously probing for vulnerabilities, 
    and leveraging that knowledge to craft a backend that is virtually impregnable.`,
    technologies: [
      {
        backend: [
          "Express.js",
          "Node.js",
          "Bcrypt",
          "CORS",
          "Passport.js",
          "Mongoose (for MongoDB interactions)",
        ],
        frontend: ["React.js", "TypeScript", "Redux Toolkit", "Material-UI"],
      },
    ],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Meticulous user input validation and sanitization, creating a strong defense against malicious exploits and maintaining the integrity of user inputs.",
      "Bcrypt encryption to fortify password security against brute-force attacks, thereby ensuring robust user data protection.",
      "The synergy of backend technologies to guarantee secure storage and transmission of user data, preventing unauthorized access and leaks.",
      "Embodiment of industry best practices, reflecting a commitment to creating a secure, efficient, and maintainable authentication system.",
      "Multi-social authentication through Google, Facebook, and GitHub accounts streamlining users experience while maintaining stringent security protocols.",
    ],
    lessonsLearned: `This journey has been a treasure trove of invaluable lessons. 
    The significance of thorough input validation has been underscored, 
    serving as a reminder that neglecting user inputs can inadvertently open doors to malicious exploitation. 
    Moreover, the vulnerabilities stemming from weak password practices, such as utilizing birthdates, 
    further emphasize the importance of advocating strong and resilient password strategies.`,
    conclusion: [
      `In conclusion, this authentication application transcends conventional paradigms to emerge 
    as a trailblazing testament to the harmonious fusion of meticulous planning, skilled implementation, and unwavering commitment to security. 
    The symphony of backend tools, ranging from the robust Express.js to the orchestrating prowess of Mongoose, 
    lays a foundation that is not only sturdy but also adaptable to the ever-evolving landscape of digital security. 
    Simultaneously, the frontend's union of TypeScript, Redux Toolkit, 
    and Material-UI culminates in a user experience that seamlessly melds aesthetic elegance with responsive functionality.`,
      `By honing in on the critical aspect of user authentication, this project effectively addresses a fundamental vulnerability and transforms it into an impervious stronghold. 
    The journey undertaken has been characterized by a constant pursuit of refinement, learning, and growth, epitomizing the spirit of cybersecurity best practices. 
    In a digital realm fraught with challenges, this application emerges as a beacon of adaptability, resilience, and unyielding dedication to the pursuit of excellence in the realm of web security.`,
    ],
  },
  {
    id: 3,
    image: "portfolio/python_blog_website_preview.png",
    title: "Full-Stack Python Blog Website",
    description: [
      `This blog website is crafted using the power of Python, where the synergy of dynamic backend technologies 
      and elegant frontend frameworks converges to create a seamless user experience. 
      This project transcends the realms of conventional blogging platforms, 
      harnessing the capabilities of Python to not only deliver captivating content but also elevate the user interface 
      and backend architecture to new heights.`,
    ],
    creativeApproach: `My creative journey delves beyond the ordinary, striving to redefine the blogging experience. 
    Embracing the versatility of Python, I have woven a narrative that seamlessly integrates user-friendly design with advanced functionalities. 
    This project is not just about content creation; 
    it's about empowering users to engage, interact, and explore in ways that are as innovative as they are intuitive.`,
    technologies: [
      {
        backend: ["HTML", "CSS", "Bootstrap", "WTForms", "Jinja"],
        frontend: [
          "Python",
          "Flask",
          "requests",
          "PostgreSQL",
          "SQLAlchemy",
          "psycopg2-binary",
        ],
      },
    ],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Leveraged Flask to facilitate a dynamic interaction with external services, enhancing content richness.",
      "Integration of SQLAlchemy and compatibility with SQLite and PostgreSQL databases establishes a versatile foundation for data storage.",
      "Modular codebase architecture supports maintainability and future enhancements.",
      "Utilized bootstrap to ensure a responsive user interface, adapting seamlessly to various devices.",
    ],
    lessonsLearned: `Throughout this endeavour, I have learned the importance of balancing innovation with user-centric design. 
    While pushing the boundaries of technology, it is crucial to maintain a seamless and intuitive user experience. 
    Additionally, the flexibility of database solutions has underscored the significance of tailoring the technology stack to the project's specific needs.`,
    conclusion: [
      `In conclusion, this Python blog website serves as a testament to the amalgamation of technological excellence and creative ingenuity. 
      By harnessing the power of Flask, SQLAlchemy, and Bootstrap, we've constructed a platform that doesn't just disseminate information, but also engages and empowers users in novel ways. 
      The collaboration of backend sophistication and frontend elegance exemplifies the potential that Python holds in transforming conventional web experiences into dynamic and captivating journeys.`,
      `As I reflect on this project, it is evident that the fusion of creativity and technology yields a truly immersive blogging encounter. 
      This website is not just a destination for content consumption; it is a stepping stone into the future of online engagement, 
      where Python's prowess meets user expectations in harmonious synchronization.`,
    ],
  },
  {
    id: 4,
    image: "portfolio/script-execution-process.png",
    title: "Automated Backup Script",
    description: [
      `This ingenious script meticulously crafted using the power of Bash Shell scripting empowers users to effortlessly safeguard their critical files
       and directories, ushering in a new era of efficient backup management. 
      With an arsenal of advanced features, including encryption, compression, remote backups, and integration with cloud storage services, 
      this script is poised to revolutionize the way you approach data backup.`,
    ],
    creativeApproach: `The world of data backup is characterized by a commitment to simplicity and efficiency. 
    By harnessing the versatility of Bash Shell scripting, I engineered a solution that automates a traditionally cumbersome task. 
    Beyond just automating backups, the script introduces innovative elements like encryption and remote backup integration, 
    providing users with an unparalleled level of control over their data.`,
    tools: ["Compression utilities", "cloud storage APIs", "Remote servers"],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "By encapsulating complex backup procedures within a user-friendly script, users can initiate backups effortlessly, thereby reducing the potential for human error.",
      "The script's integration of encryption ensures that sensitive data remains secure, while compression optimizes storage space, making it ideal for managing large datasets.",
      "The script's capacity to perform remote backups empowers users to maintain data redundancy across different locations, adding a layer of data resilience.",
      "Seamless integration with cloud storage services enables users to directly store backups in cloud repositories, enhancing accessibility and disaster recovery options.",
      "The script's built-in logging functionality provides a clear record of backup activities, facilitating oversight and troubleshooting.",
    ],
    lessonsLearned: `Throughout the development of this script, I realized the importance of striking a balance between automation and customization. 
    While automation streamlines the backup process, tailoring the script to accommodate diverse user needs ensures that it remains a versatile and adaptable solution.`,
    conclusion: [
      `In conclusion, the Automated Backup Script represents a fusion of technological excellence and user-centric design. 
      By harnessing the power of Bash Shell scripting, I have engineered a solution that not only automates the backup process 
      but also introduces advanced features like encryption, compression, remote backups, and cloud storage integration. 
      Reflecting on this endeavour, it's clear that the marriage of technology and innovation has resulted in a tool 
      that empowers users to safeguard their data with unprecedented ease and efficiency.`,
      `The Automated Backup Script is not just a utilitarian tool; it's a testament to my commitment to simplifying complex tasks and enhancing data security. 
      As the digital landscape evolves, this script stands as a beacon of adaptability, resilience, 
      and innovation—a solution designed to safeguard your data in an increasingly interconnected and data-driven world.`,
    ],
  },
  {
    id: 5,
    image: "portfolio/question-mark.png",
    title: "OWASP Top 10 SecureCoder App",
    description: [
      `SecureCoder is application designed to empower developers with the knowledge and tools they need to create robust and secure software. 
      In a world where cyber threats are ever-evolving, ensuring the security of software is paramount. SecureCoder not only educates developers on secure coding practices 
      but also provides hands-on tools to detect and prevent vulnerabilities. This innovative application harnessing the power of Python, regular expressions (regex), 
      and an acute awareness of the OWASP Top 10 vulnerabilities. `,
    ],
    creativeApproach: `The idea behind SecureCoder is to make secure coding practices accessible and engaging. 
    The concept of security can sometimes be a daunting topic, especially for developers new to the field. 
    Therefore, with a combination of education and practicality. 
    SecureCoder offers a comprehensive guide to secure coding, explaining the importance of each practice and providing real-world examples.`,
    tools: ["Python", "Regular Expressions (Regex)", "OWASP Top 10"],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "Successfully detects and prevents numerous vulnerabilities, safeguarding applications from potential threats.",
      "Automation capabilities to streamline the process of identifying and addressing vulnerabilities..",
      "Identifies vulnerabilities related to the OWASP Top 10, including issues like SQL injection, cross-site scripting (XSS), and security misconfigurations.",
      "Seamlessly integrates into existing development workflows, ensuring minimal disruption to the coding process. ",
    ],
    lessonsLearned: `In the realm of software security, the adage "Prevention Over Remediation" stands as a guiding principle. 
    This emphasizes that prioritizing security during the coding phase is significantly more efficient and cost-effective than attempting to address vulnerabilities after production. 
    Moreover, SecureCoder underscores the pivotal role of education in security. It's not merely about the tools; it's about comprehending and embracing secure coding practices. 
    SecureCoder's educational facet has illuminated the profound importance of nurturing security expertise within development teams, empowering them to proactively protect their code and applications.`,
    conclusion: [
      `The SecureCoder app represents a transformative approach to software security. 
      By harnessing the capabilities of Python, regular expressions, and the knowledge of the OWASP Top 10 vulnerabilities, it equips developers with a powerful tool to fortify their code against threats.`,
    ],
  },
  {
    id: 6,
    image: "portfolio/landing_page_wema_frontend_website.png",
    title: "WEMA: A Frontend Website",
    description: [
      `Step into the realm of frontend web development where innovation merges with aesthetics in our remarkable website project. 
      This project is a testament to precision, showcasing a frontend that harmoniously blends technology and design. 
      By harnessing the power of React.js, TypeScript, Redux Toolkit, Font Awesome, and Material-UI, 
      I have sculpted a digital masterpiece that not only dazzles the eye but also delivers a truly engaging user experience.`,
    ],
    creativeApproach: `This frontend website project revolves around the concept of harmonious interaction. 
    The marriage of cutting-edge technologies and exquisite design elements isn't just about visual appeal; it's about crafting an experience that captivates users from the moment they land on the site. 
    The project doesn't merely present information; it transforms it into an immersive narrative that unfolds with each click.`,
    tools: [
      "React.js",
      "TypeScript",
      "Redux Toolkit",
      "Font Awesome",
      "Material-UI",
    ],
    keyAchievements: [
      "Throughout the development process, I achieved several key milestones:",
      "The successful integration of Material-UI and Redux Toolkit has led to a remarkable improvement in user experience. Users now enjoy effortless navigation, a responsive design, and efficient state management.",
      "The incorporation of Font Awesome icons has elevated the project's visual appeal to new heights, creating an engaging and interactive user interface that captivates visitors.",
      "The project's architecture, empowered by TypeScript's static typing and aided by Redux Toolkit, lays a robust foundation for future scalability and easy maintenance. This ensures the project's long-term viability.",
      "Through the strategic use of Redux Toolkit and TypeScript, our project has achieved a level of code maintainability that is vital for seamless development and future enhancements.",
      "Material-UI's pre-styled components have enabled the creation of a consistently user-friendly design, greatly enhancing the overall user satisfaction and usability of the project.",
    ],
    lessonsLearned: `Throughout the development of this project, I have embraced the significance of striking a balance between aesthetics and functionality. 
    While sophisticated technologies empower us to create visually stunning interfaces, it's crucial to ensure that design doesn't compromise user experience.`,
    conclusion: [
      `The frontend website project embodies the very essence of modern web development. 
      The harmonious convergence of TypeScript, Redux Toolkit, Font Awesome, and Material-UI empowers us to create an experience that transcends mere aesthetics. 
      It's a journey where technology is interwoven with design, resulting in a digital haven that offers not just information, but an immersive narrative.`,
      `Additionally, it's evident that the future of web development lies in this synergy of innovation and design. 
      The project isn't just a website; it's an exploration of what's possible when technology and creativity come together. 
      It's a testament to my commitment to pushing the boundaries of frontend development, delivering experiences that redefine digital engagement.`,
    ],
  },
];
