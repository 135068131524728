import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grow,
  Button,
  Box,
  Link,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import InstagramIcon from "@mui/icons-material/Instagram";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import logo from "../../../assets/brand-logo/logo.png";

const MobileMenuButton = styled(Button)`
  font-size: 1.1rem;
  text-transform: lowercase;
  .MuiButton-root:hover {
    background-color: transparent;
  }
  &&.MuiButton-root:active {
    background-color: transparent;
  }
`;
const MenuButton = styled(Button)(({ theme }) => ({
  marginRight: "0.5rem",
  textTransform: "lowercase",
  fontSize: "1.10rem",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "&&:active": {
    backgroundColor: "transparent",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const SocialIconsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  maxWidth: "200px",
}));

const SocialButtons = styled(Button)`
  &.MuiButton-root:hover {
    background-color: transparent;
  }
  &&.MuiButton-root:active {
    background-color: transparent;
  }
`;

export default function NavBar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuOpen = () => setMobileMenuOpen(true);
  const handleMobileMenuClose = () => setMobileMenuOpen(false);

  const isMobile = useMediaQuery("(max-width:600px) or (max-width:900px)");

  return (
    <AppBar id="back-to-top-anchor" position="static">
      <Toolbar sx={{ "@media all": { minHeight: 75 } }}>
        <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
          <Link href="/" underline="none">
            <img
              src={logo}
              alt="Logo"
              height="40px"
              style={{ marginRight: "16px" }}
            />
          </Link>
          <Link href="/" underline="none">
            <Typography variant="h6" component="div">
              Verdant
            </Typography>
          </Link>
        </Box>
        {isMobile ? (
          <Box ml="auto">
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              {mobileMenuOpen === false ? (
                <MenuIcon sx={{ fontSize: 30 }} />
              ) : (
                <CloseIcon sx={{ fontSize: 30 }} />
              )}
            </IconButton>
            <Menu
              anchorEl={mobileMenuOpen}
              open={mobileMenuOpen}
              onClose={handleMobileMenuClose}
              TransitionComponent={Grow}
              TransitionProps={{
                direction: "down",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  bgcolor: "rgba(0, 196, 159, 0.9)",
                  borderRadius: 1,
                  marginTop: 7,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "rgba(0, 196, 159, 0.9)",
                    transform: "translateY(-50%) rotate(45deg)",
                  },
                },
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MenuItem
                  onClick={handleMobileMenuClose}
                  sx={{
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <MobileMenuButton href="/work" color="inherit" variant="text">
                    work
                  </MobileMenuButton>
                </MenuItem>
                <MenuItem onClick={handleMobileMenuClose}>
                  <MobileMenuButton
                    href="/whoami"
                    color="inherit"
                    variant="text"
                  >
                    whoami
                  </MobileMenuButton>
                </MenuItem>
                <MenuItem onClick={handleMobileMenuClose}>
                  <MobileMenuButton
                    href="/contact"
                    color="inherit"
                    variant="text"
                  >
                    contact
                  </MobileMenuButton>
                </MenuItem>
                <MenuItem onClick={handleMobileMenuClose}>
                  <MobileMenuButton
                    href="/journal"
                    color="inherit"
                    variant="text"
                  >
                    journal
                  </MobileMenuButton>
                </MenuItem>
              </Box>
              <Divider sx={{ my: 0.5 }} />
              <SocialIconsContainer>
                <MenuItem onClick={handleMobileMenuClose}>
                  <SocialButtons
                    href="https://www.instagram.com/cryupsoul/"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <InstagramIcon />
                  </SocialButtons>
                  <SocialButtons
                    href="https://github.com/ggg6r34t"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <GitHubIcon />
                  </SocialButtons>
                  <SocialButtons
                    href="https://www.linkedin.com/in/ghyorkakpee"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <LinkedInIcon />
                  </SocialButtons>
                  <SocialButtons
                    href="mailto:ghyorka.2020@gmail.com"
                    rel="noopener"
                    color="inherit"
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <EmailIcon />
                  </SocialButtons>
                </MenuItem>
              </SocialIconsContainer>
            </Menu>
          </Box>
        ) : (
          <Box ml="auto" display={{ xs: "none", sm: "block" }}>
            <MenuButton href="/work" color="inherit" variant="text">
              work
            </MenuButton>
            <MenuButton href="/whoami" color="inherit" variant="text">
              whoami
            </MenuButton>
            <MenuButton href="/contact" color="inherit" variant="text">
              contact
            </MenuButton>
            <MenuButton href="/journal" color="inherit" variant="text">
              journal
            </MenuButton>

            <SocialButtons
              href="https://www.instagram.com/cryupsoul/"
              target="_blank"
              rel="noopener"
              color="inherit"
              style={{ marginLeft: "0.5rem" }}
            >
              <InstagramIcon />
            </SocialButtons>
            <SocialButtons
              href="https://github.com/ggg6r34t"
              target="_blank"
              rel="noopener"
              color="inherit"
              style={{ marginLeft: "0.5rem" }}
            >
              <GitHubIcon />
            </SocialButtons>
            <SocialButtons
              href="https://www.linkedin.com/in/ghyorkakpee"
              target="_blank"
              rel="noopener"
              color="inherit"
              style={{ marginLeft: "0.5rem" }}
            >
              <LinkedInIcon />
            </SocialButtons>
            <SocialButtons
              href="mailto:ghyorka.2020@gmail.com"
              rel="noopener"
              color="inherit"
              style={{ marginLeft: "0.5rem" }}
            >
              <EmailIcon />
            </SocialButtons>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
