import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import theme from "./components/theme/theme";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import BackToTop from "./components/layout/BackToTop";
import PageNotFound from "./components/pages/errors/NotFound";
import routes from "./routes";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => (
      <Route path={route.path} element={<route.component />} key={route.path} />
    ));

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Header />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <BackToTop />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
