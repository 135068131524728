import CodeIcon from "@mui/icons-material/Code";
import SecurityIcon from "@mui/icons-material/Security";

export const skillsData = [
    {
        category: "Full Stack Development",
        icon: <CodeIcon />,
        skills: [
            { name: "HTML", level: "Expert" },
            { name: "CSS", level: "Advanced" },
            { name: "React", level: "Expert" },
            { name: "JavaScript", level: "Advanced" },
            { name: "Node.js", level: "Advanced" },
            { name: "TypeScript", level: "Advanced" },
            { name: "Python", level: "Expert" },
            { name: "Material UI", level: "Advanced" },
        ],
    },
    {
        category: "Cybersecurity",
        icon: <SecurityIcon />,
        skills: [
            { name: "Git & GitHub", level: "Expert" },
            { name: "Information Security", level: "Advanced" },
            { name: "Vulnerability assessment", level: "Expert" },
            { name: "Penetration testing", level: "Advanced" },
            { name: "Risk management", level: "Advanced" },
            { name: "Technical support", level: "Expert" },
            { name: "Security awareness training", level: "Expert" },
            { name: "Phishing emails", level: "Expert" },
        ],
    },
];