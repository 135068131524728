import React from "react";
import {
  Container,
  Typography,
  Grid,
  Button,
  Divider,
  useMediaQuery,
} from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@material-ui/icons/Telegram";

import ContactForm from "./ContactForm";

export default function ContactMe() {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Container
      maxWidth="md"
      sx={{
        mt: 10.7,
        "@media (max-width: 600px), (max-width: 900px)": { marginTop: "unset" },
      }}
    >
      <Typography
        variant="h1"
        align="left"
        sx={{
          fontSize: isSmallScreen ? 70 : 85,
          mb: 2,
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        Contact
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Let's Connect
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Have a question or want to discuss a project? Feel free to reach out
            to me through any of the following channels:
          </Typography>
          <Button
            variant="outlined"
            startIcon={<EmailIcon />}
            href="mailto:ghyorka.2020@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mr: 2, mb: 2 }}
          >
            Email
          </Button>
          <Button
            variant="outlined"
            startIcon={<GitHubIcon />}
            href="https://github.com/ggg6r34t"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mr: 2, mb: 2 }}
          >
            GitHub
          </Button>
          <Button
            variant="outlined"
            startIcon={<LinkedInIcon />}
            href="https://www.linkedin.com/in/ghyorkakpee"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mr: 2, mb: 2 }}
          >
            LinkedIn
          </Button>
          <Button
            variant="outlined"
            startIcon={<TelegramIcon />}
            href="https://t.me/redshirtman"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mr: 2, mb: 2 }}
          >
            Telegram
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Hire Me
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Looking for a skilled coder/web developer or an ethical hacker for
            your project or organization? I'm available for freelance work,
            collaborations, or full-time opportunities.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            Let's discuss your requirements and how I can contribute to your
            success. Get in touch with me using the contact details above or by
            filling out the form below:
          </Typography>
          <ContactForm />
        </Grid>
      </Grid>
      <Divider sx={{ my: 4 }} />
      <Typography
        variant="body1"
        align="center"
        sx={{ paddingBottom: "100px" }}
      >
        Don't hesitate to reach out to me. I'm excited to hear from you and
        explore how we can work together to achieve amazing things.
      </Typography>
    </Container>
  );
}
