import {
  faCode,
  faGlobe,
  faBook,
  faHiking,
  faMusic,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";

export const funFactsData = [
  {
    emoji: "😄",
    label: "happy emoji",
    fact: `I love puzzles, whether it's watching puzzles being solved or
    solving them myself!`,
  },
  {
    emoji: "🎉",
    label: "celebrate emoji",
    fact: `I enjoy attending cybersecurity/tech live streams!`,
  },
  {
    emoji: "🕺🏻",
    label: "dancing emoji",
    fact: `I once danced so much that my legs had muscle spasms and I
    couldn't walk.`,
  },
  {
    emoji: "🌕",
    lable: "moon emoji",
    fact: `My favorite childhood memory is playing under the moonlight,
    free from light pollution.`,
  },
];

export const interestsData = [
  {
    icon: faCode,
    title: "Technology",
    description: "Exploring new gadgets and emerging technologies.",
  },
  {
    icon: faGlobe,
    title: "Travel",
    description: "Visiting new places, experiencing different cultures.",
  },
  {
    icon: faBook,
    title: "Learning",
    description:
      "Dedicated to mastering new programming languages and cloud technologies.",
  },
  {
    icon: faHiking,
    title: "Outdoor Activities",
    description: "Football, table tennis, and biking.",
  },
  {
    icon: faGamepad,
    title: "Gaming",
    description: "Enjoy playing video games with a passion for FIFA.",
  },
  {
    icon: faMusic,
    title: "Music",
    description: "Playing instruments and discovering new artists.",
  },
];
