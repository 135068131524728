import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  Grid,
  Select,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";

import { journalData } from "../../../data/journalData";

const RootContainer = styled.div`
  padding: 24px;
  padding-bottom: 100px;
  margin-top: 67px;

  @media (max-width: 600px), (max-width: 900px) {
    margin-top: unset;
  }
`;

const CategoryCard = styled(Card)`
  && {
    margin-bottom: 16px;
    margin-right: 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 245px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media (max-width: 1024px) {
      width: 190px;
    }

    @media (max-width: 600px), (max-width: 900px) {
      width: 220px;
    }
  }

  &:hover {
    background-color: #00c49f;
  }

  &.active {
    background-color: #00c49f;
  }
`;

const CategorySubtitle = styled(Typography)`
  && {
    font-weight: 900;
    font-size: 40px;
  }
`;

const JournalCard = styled(Card)`
  margin-bottom: 16px;
  min-height: 200px;
  max-height: 485px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const JournalCardContent = styled(CardContent)(() => ({
  flexGrow: 1,
  height: "100%",
  overflow: "hidden",
  "@media (max-width:600px) or (max-width:900px)": {
    overflow: "visible",
  },
}));

const JournalCardMedia = styled(CardMedia)`
  height: 200px;

  @media (max-width: 1024px) {
    height: 170px;
  }

  @media (max-width: 600px) {
    height: 135px;
  }
`;

const JournalContent = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`;

const Journal = ({ title, date, image, content, journal, openPost }) => (
  <JournalCard>
    <CardHeader title={title} subheader={date} />
    <JournalCardMedia
      component="img"
      image={require(`../../../assets/journal/${image}`)}
      alt={title}
    />
    <JournalCardContent>
      <JournalContent variant="body1">{content}</JournalContent>
      <Button variant="outlined" onClick={() => openPost(journal)}>
        Read More
      </Button>
    </JournalCardContent>
  </JournalCard>
);

const JournalList = ({ journals, selectedCategory }) => {
  const filteredJournals =
    selectedCategory === "All"
      ? journals
      : journals.filter((journal) => journal.category === selectedCategory);

  const navigate = useNavigate();

  const openPost = (data) => {
    let categoryPath = "";

    switch (data.category) {
      case "Writing":
        categoryPath = "/writings";
        break;
      case "Poems":
        categoryPath = "/poetry";
        break;
      default:
        categoryPath = "/thoughts";
        break;
    }

    navigate(`/journal${categoryPath}/${data.title}`, { state: { data } });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={2}>
        {filteredJournals.map((journal) => (
          <Grid item xs={12} sm={6} md={4} key={journal.id}>
            <Journal
              title={journal.title}
              image={journal.image}
              date={journal.date}
              content={journal.content}
              journal={journal}
              openPost={openPost}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const CategoryFilter = ({ selectedCategory, handleCategoryChange }) => {
  const isMobile = useMediaQuery("(max-width:600px) or (max-width:900px)");

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={2} md={8}>
        <Grid item xs={12}>
          <Typography variant="h6">Categories</Typography>
        </Grid>
        {isMobile ? (
          <Grid item xs={12}>
            <Select
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Thoughts">Thoughts</MenuItem>
              <MenuItem value="Writing">Writings</MenuItem>
              <MenuItem value="Poems">Poems</MenuItem>
            </Select>
          </Grid>
        ) : (
          <Grid
            container
            spacing={4}
            wrap="nowrap"
            alignItems="center"
            style={{
              marginLeft: 35,
              paddingBottom: 50,
            }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <CategoryCard
                onClick={() => handleCategoryChange("All")}
                className={selectedCategory === "All" ? "active" : ""}
              >
                <CardContent>
                  <CategorySubtitle id="category-All" variant="subtitle1">
                    All
                  </CategorySubtitle>
                </CardContent>
              </CategoryCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CategoryCard
                onClick={() => handleCategoryChange("Thoughts")}
                className={selectedCategory === "Thoughts" ? "active" : ""}
              >
                <CardContent>
                  <CategorySubtitle id="category-Thoughts" variant="subtitle1">
                    Thoughts
                  </CategorySubtitle>
                </CardContent>
              </CategoryCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CategoryCard
                onClick={() => handleCategoryChange("Writing")}
                className={selectedCategory === "Writing" ? "active" : ""}
              >
                <CardContent>
                  <CategorySubtitle id="category-Writing" variant="subtitle1">
                    Writings
                  </CategorySubtitle>
                </CardContent>
              </CategoryCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CategoryCard
                onClick={() => handleCategoryChange("Poems")}
                className={selectedCategory === "Poems" ? "active" : ""}
              >
                <CardContent>
                  <CategorySubtitle id="category-Poems" variant="subtitle1">
                    Poems
                  </CategorySubtitle>
                </CardContent>
              </CategoryCard>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default function JournalPage() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    const categoryElement = document.getElementById(`category-${category}`);
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <RootContainer maxWidth="lg">
        <Container>
          <Typography
            variant="h1"
            align="left"
            sx={{
              fontWeight: "bold",
              fontSize: isSmallScreen ? 70 : 85,
              mb: 2,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            journal
          </Typography>
          <Grid item xs={12} sm={8}>
            <Box component="main" sx={{ p: 2 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                "Pen in hand, heart alight, the journal becomes a mirror,
                reflecting the depths of our being and the vastness of our
                dreams."
              </Typography>
              <Typography variant="body1" sx={{ mb: 4 }}>
                Welcome to my journal, where thoughts, experiences, and emotions
                intertwine. Here, I embark on a journey of self-reflection,
                personal growth, and creativity. It is within these lines that
                stories unfold and ideas come to life, where words become
                whispers of the soul.
              </Typography>
            </Box>
          </Grid>

          <CategoryFilter
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
          <JournalList
            journals={journalData}
            selectedCategory={selectedCategory}
          />
        </Container>
      </RootContainer>
    </div>
  );
}
