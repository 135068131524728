import React from "react";
import { useLocation } from "react-router-dom";
import { CardMedia, Container, Typography } from "@mui/material";

import PageNotFound from "../errors/NotFound";

export default function WorkPost() {
  const location = useLocation();
  const { data } = location.state;

  if (!data) {
    return <PageNotFound />;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 4, paddingTop: 8, paddingBottom: "68px" }}
      key={data.id}
    >
      <CardMedia
        sx={{
          backgroundPosition: "center",
          height: 486,
          mb: 2,
        }}
        image={require(`../../../assets/journal/${data.image}`)}
        alt={data.title}
      />
      <Typography variant="h2" component="h1" sx={{ mb: 2 }}>
        {data.title}
      </Typography>
      <Typography variant="body2" sx={{ color: "black", mb: 2 }}>
        {data.date}
      </Typography>

      {data.content.slice(0, 3).map((content, index) => (
        <Typography key={index} variant="body1" sx={{ mb: 4 }}>
          {content}
        </Typography>
      ))}

      {data.additionalImage && (
        <>
          <CardMedia
            sx={{ height: 486, mb: 2, backgroundPosition: "center top" }}
            image={require(`../../../assets/journal/${data.additionalImage[0]}`)}
            title={data.title}
          />
          <CardMedia
            sx={{ height: 604, mb: 4, backgroundPosition: "center top" }}
            image={require(`../../../assets/journal/${data.additionalImage[1]}`)}
            title={data.title}
          />
        </>
      )}

      {data.content.slice(3).map((content, index) => (
        <Typography key={index} variant="body1" sx={{ mb: 4 }}>
          {content}
        </Typography>
      ))}
    </Container>
  );
}
