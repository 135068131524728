import LanguageIcon from "@material-ui/icons/Language";
import CodeIcon from "@material-ui/icons/Code";
import SecurityIcon from "@material-ui/icons/Security";
import AssessmentIcon from "@material-ui/icons/Assessment";
import PublicIcon from "@material-ui/icons/Public";
import BuildIcon from "@material-ui/icons/Build";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import WebIcon from "@material-ui/icons/Web";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import LaptopChromebookIcon from "@material-ui/icons/LaptopChromebook";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import ExtensionIcon from "@material-ui/icons/Extension";
import CloudIcon from "@material-ui/icons/Cloud";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SettingsIcon from "@material-ui/icons/Settings";
import ComputerIcon from "@material-ui/icons/Computer";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";

export const technicalSkills = [
  {
    name: "HTML",
    icon: <LanguageIcon />,
    proficiency: 85,
  },
  {
    name: "CSS",
    icon: <WebIcon />,
    proficiency: 80,
  },
  {
    name: "JavaScript",
    icon: <CodeIcon />,
    proficiency: 90,
  },
  {
    name: "Tenable Nessus",
    icon: <SecurityIcon />,
    proficiency: 70,
  },
  {
    name: "Problem Solving",
    icon: <AssessmentIcon />,
    proficiency: 85,
  },
  {
    name: "Metasploit",
    icon: <PublicIcon />,
    proficiency: 80,
  },
  {
    name: "Web Application Security",
    icon: <BuildIcon />,
    proficiency: 75,
  },
  {
    name: "Software Testing",
    icon: <CloudDoneIcon />,
    proficiency: 80,
  },
  {
    name: "Cloud Security",
    icon: <SecurityOutlinedIcon />,
    proficiency: 75,
  },
  {
    name: "Cloud Development",
    icon: <CloudIcon />,
    proficiency: 70,
  },
  {
    name: "Full-Stack Development",
    icon: <BuildOutlinedIcon />,
    proficiency: 85,
  },
  {
    name: "Docker Products",
    icon: <CloudDownloadIcon />,
    proficiency: 75,
  },
  {
    name: "Web Development",
    icon: <WebIcon />,
    proficiency: 90,
  },
  {
    name: "REST",
    icon: <SettingsEthernetIcon />,
    proficiency: 75,
  },
  {
    name: "Git",
    icon: <DeveloperModeIcon />,
    proficiency: 85,
  },
  {
    name: "SQLAlchemy",
    icon: <ExtensionIcon />,
    proficiency: 70,
  },
  {
    name: "IT Service Management",
    icon: <BuildOutlinedIcon />,
    proficiency: 80,
  },
  {
    name: "Cloud Computing",
    icon: <CloudIcon />,
    proficiency: 60,
  },
  {
    name: "DevOps",
    icon: <BuildOutlinedIcon />,
    proficiency: 80,
  },
  {
    name: "AWS",
    icon: <CloudIcon />,
    proficiency: 80,
  },
  {
    name: "Software Development",
    icon: <ComputerIcon />,
    proficiency: 75,
  },
  {
    name: "Redux.js",
    icon: <FlashOnIcon />,
    proficiency: 75,
  },
  {
    name: "Responsive Web Design",
    icon: <LaptopChromebookIcon />,
    proficiency: 80,
  },
  {
    name: "TypeScript",
    icon: <CodeIcon />,
    proficiency: 80,
  },
  {
    name: "React.js",
    icon: <LaptopMacIcon />,
    proficiency: 90,
  },
  {
    name: "Back-End Web Development",
    icon: <SettingsIcon />,
    proficiency: 85,
  },
  {
    name: "Front-End Development",
    icon: <WebIcon />,
    proficiency: 90,
  },
  {
    name: "Security Consulting",
    icon: <FiberManualRecordIcon />,
    proficiency: 75,
  },
];
