export const journalData = [
  {
    id: 1,
    title: "Random Thought #1",
    date: "May 1, 2023",
    content:
      "Life is a journey of ups and downs, but it is in the downs where we truly learn and grow.",
    category: "Thoughts",
    image: "thoughts/growing_tree_with_roots.png",
  },
  {
    id: 2,
    title: "Poem: The Beauty of Nature",
    date: "May 3, 2023",
    content: [
      `In the realm of emerald green,
            Where sunlight dances upon leaves unseen,
            The gentle whisper of the wind's embrace,
            Unveils the beauty of nature's grace.`,

      `A symphony of colors, vibrant and bold,
            A canvas painted with stories untold,
            Flowers blooming in a fragrant array,
            Swaying to the rhythm of a melody at play.`,

      `The mountains rise, majestic and grand,
            Reaching for the heavens, a touch of the hand,
            Their peaks kissed by clouds, a sight divine,
            A sanctuary where harmony aligns.`,

      `Rivers meandering through valleys below,
            Carving pathways where secrets may flow,
            Reflecting the sky in a shimmering hue,
            A mirror of dreams, both old and new.`,

      `And as the sun sets, painting the sky,
            With hues of gold and crimson dye,
            Nature whispers a lullaby of peace,
            Embracing the world in a gentle release.`,

      `Oh the beauty of nature, boundless and true,
            A source of inspiration, a love that grew,
            Let us cherish and protect this precious land,
            And together, in harmony, let us stand.`,
    ],
    category: "Poems",
    image: "poems/beauty_of_nature.png",
  },
  {
    id: 3,
    title: "Writing: Reflections on a Journey",
    date: "May 5, 2023",
    content: [
      `The path less traveled often reveals the greatest treasures. It is in the exploration of unknown territories, both external and internal, that we discover our true selves.`,

      `This journey has been filled with moments of triumph and moments of doubt. There were times when I questioned my choices, my purpose, and my direction. But through it all, I've learned that every step, every experience, has shaped me into who I am today.`,

      `Along the way, I've encountered diverse landscapes and encountered fascinating souls. Each encounter has left an indelible mark, a story to be shared. These stories weave a tapestry of connections that remind me of the beauty of humanity and the power of empathy.`,

      `It is in embracing vulnerability that we find strength. It is in embracing imperfections that we find beauty. And it is in embracing the unknown that we find limitless possibilities.`,

      `So, I continue to wander, to explore, and to seek. With an open heart and an open mind, I embrace the challenges and the joys that lie ahead. For it is through this journey, this ever-evolving narrative, that I find my purpose and my place in this vast universe.`,

      `Let the winds guide me, let the stars inspire me, as I continue to write my story, one page at a time.`,
    ],
    category: "Writing",
    image: "writings/reflections_on_a_journey.png",
  },
  {
    id: 4,
    title: "Random Thought #2",
    date: "May 10, 2023",
    content: `Sometimes, the most profound discoveries come from embracing simplicity. In a world filled with complexity, simplicity can be a refreshing oasis for the mind and the soul.`,
    category: "Thoughts",
    image: "thoughts/simplicity_in_a_complex_world.png",
  },
  {
    id: 5,
    title: "Poem: Serenade of the Night",
    date: "May 15, 2023",
    content: [
      `Beneath the velvet cloak of night,
          Where stars ignite the sky's delight,
          I listen to the serenade unfold,
          As whispers of secrets are gently told.`,

      `The moon, a radiant beacon above,
          Illuminating the realm of love,
          Casting its glow on hidden dreams,
          Bathing the world in silver beams.`,

      `The nocturnal symphony begins,
          A chorus of creatures, nature's hymns,
          Crickets chirping, a melody so sweet,
          Nightingales trilling in rhythm complete.`,

      `Fireflies dance, their flickering light,
          Creating enchantment in the twilight,
          A mesmerizing display, fleeting and bright,
          A magical wonder, a pure delight.`,

      `I lose myself in this nocturnal embrace,
          Finding solace in the stillness of space,
          For in the depths of night's embrace,
          I find tranquility, a sacred place.`,

      `And as the night gently fades away,
          Yielding to the dawn, a brand new day,
          I carry the serenade within my heart,
          A melody of the night, never to depart.`,
    ],
    category: "Poems",
    image: "poems/nocturnal_symphony.png",
  },
  {
    id: 6,
    title: "Writing: A Journey Within",
    date: "May 20, 2023",
    content: [
      `
          In the depths of self-discovery,
          A journey unfolds, an odyssey,
          To unravel the layers that lie within,
          To explore the depths where truths begin.`,

      `A tapestry of memories and fears,
          A well of emotions, both smiles and tears,
          Seeking answers to questions unknown,
          Embracing the seeds of growth sown.`,

      `It is in the quiet moments of introspection,
          Where whispers of the soul find reflection,
          That we uncover the gems hidden deep,
          A treasure trove of secrets to keep.`,

      `Through self-reflection, we learn and grow,
          Embracing the parts we've come to know,
          Honoring the past, embracing the present,
          Nurturing the future, a gift, a crescent.`,

      `So, let us embark on this inner voyage,
          With courage and curiosity, we shall engage,
          For the journey within is an endless quest,
          A constant unfolding, a soul's behest.`,

      `May we discover the beauty we possess,
          Embrace our flaws and our strengths, no less,
          And in the depths of our being, may we find,
          A sense of purpose, a love unconfined.`,
    ],
    category: "Writing",
    image: "writings/enlightened_self_aware.png",
  },
  {
    id: 7,
    title: "Poem: Weeds Vs Man",
    author: "Ghyorka Kpee",
    date: "July 8, 2023",
    content: [
      `
            Seasons pass,
                Rain falls,
                Soiling the earth.
                The sun shines,
                Drying the earth,
                Bringing forth the heat.
                Yet the weeds do not complain.`,

      `Seasons pass,
                Rain falls,
                Soiling the earth.
                Man yearns for the sun,
                The sun shines,
                Drying the earth,
                Bringing forth the heat.
                Man yearns for the rain.
                Man is never content.`,

      `Seasons pass,
                Nature's rhythm unfolds,
                Teaching us lessons untold.
                While the earth embraces change,
                Man forever seeks to rearrange.`,

      `In nature's dance, harmony resides,
                Accepting what each season provides.
                But man, in his relentless chase,
                Fails to find contentment's embrace.`,

      `Let us learn from the earth's wise decree,
                To accept and cherish what we see.
                For in the balance of rain and sun,
                Lies the beauty of life, ever undone.`,

      `As seasons pass, let us aspire,
                To find peace within, a heart afire.
                In unity with nature's flow,
                We'll discover the contentment we sow.`,
    ],
    category: "Poems",
    image: "poems/tall_grass_pencil_drawing-removebg.png",
  },
  {
    id: 8,
    title: "Random Thought #3",
    author: "Ghyorka Kpee",
    date: "July 8, 2023",
    content: `The thoughts that speak volumes do so softly, for an empty can makes the loudest noise. Therefore, amidst the concert of loud and noisy thoughts, seek out the one that speaks softly, as it often carries profound meaning in the midst of the clamor.`,
    category: "Thoughts",
    image: "thoughts/bunch_of_empty_cans.png",
  },
  {
    id: 9,
    title: "Writing: My first website",
    date: "May 26, 2021",
    content: [
      `
            My phone lights up and what I see is, “you need a website RIGHT NOW!! (create a website for FREE)”. 
                This was the notification that popped up on my phone. 
                I clicked on the notification to watch the YouTube video posted by NetworkChuck and watched it several times to let his words sink in about why I needed a website. 
                I have never owned a website before, nor have I ever blogged, and with my very busy schedule with my internship, work, and side hustles. 
                How will I have the time to blog and constantly update the content of my website I asked myself. 
                These thoughts, accompanied by many other similar thoughts, were the type of thoughts that crossed my mind when I was thinking of taking this step. 
                On the other hand, I weighed the benefits of owning a website, and how that could help inform potential employers and friends about what I have been up to, and how I progress with my various daily activities. 
                The decision was made, I will set up my website.`,

      `That is how it all began. I followed NetworkChuck's idea of creating a very basic website using WordPress and free hosting offered by 000webhost and paid for an inexpensive domain. 
                Keeping everything simple and personalized, I used my full name as the name of the website. 
                I wanted a minimalistic theme, so I made sure that the theme I went for was not overcrowded with text, images, or colours. 
                Honestly, I went over a few themes before I found what met my needs and style. When it came to deciding what to include on the website, NetworkChuck’s YouTube video had in its video description website inspiration belonging to people who had already set up a website. 
                Hence, I went through all the website inspiration suggested, from one menu to another, one page to another, scanned through the post they had and the website design they used. When I finally had what I wanted, I began including one element at a time to the website and this took quite some time to get everything in place.`,

      `Discouragement set in again. Maybe I shouldn't have started this. 
                Look how much time it is taking, and I have other programs that I needed to conclude. 
                Another set of thoughts trying to keep me away from a good idea proposed by NetworkChuck and my goal of “just do it”. 
                Despite all the thoughts going through my mind, and all the things I needed to learn to make the website possible, I kept pushing. 
                Putting everything together and having what you see now took loads of tutorial videos, and trial and error, I even had to learn a tiny bit of code to make it happen.`,

      `This piece was written to welcome you to my website and share with you how it all got started. 
                Secondly, to show you that the hardest part of starting anything is the first step, coupled with all the thoughts that go through our minds. 
                Discouraging thoughts that erect a wall of reasons why we cannot do it, and even when we find a way through those thoughts. 
                Thoughts about how much of ourselves, and time needed to invest and attain that which we are embarking on sets in, still trying to discourage us. 
                As a student, managing my finance is something I am very conscious of because the cash flow does not always come in as they go. 
                Thus, I must be prudent and wise with my spending and ensure that whatever I put my money into is worth it. 
                This type of thought did, in fact, cross my mind, but I believe to have had this started was indeed wise. 
                Because this might help that one person who needed to hear or read something like this get started with whatever they have lying dormant and catching dust. 
                So, there you go, from one notification to my first website, and now my first blog post. Once again, welcome to my website.`,
    ],
    category: "Writing",
    image: "writings/idea_light_bulb.jpg",
    additionalImage: [
      "writings/old_website_home.png",
      "writings/old_website_pages.png",
    ],
  },
  {
    id: 10,
    title: "Writing: Locked down?",
    date: "June 21, 2021",
    content: [
      `
    It all started in March 2020; various countries were all preparing to go on lockdown because of Covid-19. 
    The lockdown would change everything, including our perception of life as we knew and loved it. 
    “Everything happens for a reason” did not seem to make any sense at all as we were asked to abandon our way of life for a new one that restricted us. 
    Because of all these changes, my home became the university classroom, library, and everything it used to be before the change all in one brief space. 
    This was an unfortunate situation as many saw it, including myself for an instance but a blessing for those who managed to look past the chaos that came with Covid-19.`,

      `Seeing that I would spend most of my time at home, I did not know exactly how I was going to use up all the free time I saw coming. 
      However, the thing that helped me the most was that I had just recently concluded with my university minor in applied intelligence and cybersecurity and so I had something to think about. 
      In applied intelligence for one of our classes, we had a few guest speakers over. One of the guest speakers was in the military and showing us interrogation techniques, surveillance, and so on. 
      The other was ethical hacking and was showing how information gathering and social engineering worked for the class. I was so intrigued about what he said that after that class, a few students and I went to have coffee with him to learn more, and the more I heard the more I said to myself I want to do that, I know I can do that. 
      At the end of having coffee, we asked for guidance if we wanted to follow his path. He encouraged us and pointed us in the right direction regarding things to learn and what path to follow. 
      Thus, from that day forth I decided to pursue courses in applied intelligence and cybersecurity, especially ethical hacking, and social engineering, as I saw I was very passionate about those subjects. 
      I began talking about social engineering to friends who had taken up other minors, but my fascination did not stop at just talking about it. I began searching on the internet about topics that have to do with social engineering, ethical hacking, and open-source intelligence and how one could get started. 
      Luckily for me, there were loads of courses on Udemy that taught about the subject, and immediately hopped on them.`,

      `In my research for courses I could sign up for on the internet, and when I found something I liked I would group them. 
      I had categories for programming classes (this has been a long-time passion of mine which is also a necessary skill for ethical hacking for writing scripts and so on), also created groups for hacking, Linux, and networking. 
      As long as they complemented what I wanted to learn, I included them in my study plan. Sometimes I would have courses with the same subject but different tutors just to get a different perspective on the same topic. 
      I would usually start studying with the one that seemed to be the easiest to understand, just to give myself a bit of a foundation. 
      As I mentioned before, I had a work plan. The study plan contained everything I wanted to learn and how much time I was devoting to each course. 
      Also, I included my university courses into that study plan, so I do not fall short of what I needed to do for my study. This was how I got started. 
      I had the courses I wanted to learn and a plan on how I was going to learn them. Thus, the learning the began.`,

      `I want to mention the importance of note-taking, as it helped me absorb the information I was trying to assimilate. 
      For the ethical hacking course, I made a very comprehensive note because I thought I want to be able to quickly refer to the information by just flipping a few or a bunch of pages. 
      So for the lessons, I paused, made notes on the notebook I had got for the lesson, and that is how I went on until the end of the course. 
      I created a teacher and student mentality for myself but with flexibility in mind so that I could make time to rest and catch up. 
      Also, in staying motivated, while playing teacher and student, I was constantly reminding myself why I started and looked at how far I have come. 
      If it ever felt boring, I will switch to one of the other courses that I had also saved and learn from there, but I will always come back to continue from where I had stopped with the course I switched from.`,

      `It can get very tiring repeating the same task every day and having this never-changing chain of events. 
      Although I rarely felt this way because I was very enthusiastic about the course I was learning and the things I could do. 
      However, whenever I felt like this, I took a break from studying or learning anything new just to recharge, which seemed to help a lot. 
      Because there were times, I pushed too much that my body could gave out, and I fell ill. Never wanted that to happen again because of my study.`,
      `Three of the major lessons I have learned in the way I went about this course are to be consistent, patient, and take little steps. 
      What I mean by taking little steps is that it is easy to just skip a topic because you do not quite understand it. 
      Taking little steps in this scenario for me meant I had to watch where that step I took went. 
      For example, I came across topics that I did not grasp at an instant, but I did not just skip the topic and moved on to the next, what I did, surprise, surprise, was I went to the internet to look for that same information but from sources that explained it in a manner that was easy for me to understand, and when I went back to the course I immediately understood that which was difficult to grasp. 
      Consistency, patience, and little steps are all intertwined, like one cannot do without the other. I have carried these lessons and applied them to other areas of my life as well.`,
      `Finally, a question I ask myself is, where am I going with all this? And honestly, I do not have an exact answer. 
      However, what I know for sure is that this is just the beginning of something beyond my imagination. I am working towards various certifications in the field and taking other areas to learn about. 
      Hence, I know I am going somewhere but without a fixed destination so I do not turn a blind eye towards the other elements that could get me to my destination quicker. 
      There we go. Locked down, but not locked out of all the possibilities that came with having to stay at home.`,
    ],
    category: "Writing",
    image: "writings/locked_down_street.png",
  },
  {
    id: 11,
    title: "Writing: Journey Through Thesis",
    date: "June 1, 2021",
    content: [
      `
      At the start of the first term of the third year of my study Safety and Security Management Studies (SSMS), going on internship, writing my thesis, and preparing for graduation felt like something that was in the distant future.`,

      `Though my stomach ached a bit every time I thought of what the fourth year had in store and how many students dreaded that year. Coupled with the hassle of finding an internship placement and when you do finally find a placement, working on your thesis and doing all that research all aided to my stomach ache. 
      I thought to myself that the fourth year should be one of the easiest years of my study. Given that I knew I would not have to study and prepare for exams as I did for the preceding years of my study, it will just be me in the professional world showcasing everything my study has taught me. 
      For instance, in classes such as professional abilities, which we had in my study. 
      It taught us everything from how to send a professional email, communicate effectively, make appointments with our boss to giving an excellent PowerPoint presentation. 
      Thus, I believed I had all the tools I needed, and indeed I would say I did. However, then came Covid-19, something no one expected. 
      There were no tools suitable for the situation that it brought. The change that needed to be made was brute and demanded haste if we wanted to stay on track somehow. 
      This was barely at the end of the second quarter of the fourth where we are all still trying to secure a placement. 
      The first batch of students had not all gotten their placement, which made it even more difficult for the second and subsequent batches.`,

      `This was the time I was in search of an internship placement. At the time, my study had been provided with a company through its professional network. I was at the emailing stage, trying to secure an interview and possibly a placement with that company. 
      However, we were all dealing with a very volatile situation (Covid-19, lockdown, and restrictions) that we did not know how to contain or incorporate into our daily function. 
      Thus, my application to that company yielded nothing tangible. From that point onwards, it felt like a ripple effect. 
      I sent in emails after emails with my CV and motivational letter attached, which I believed was great to various companies, but it was one rejection after the other. 
      Some companies would not even reply to the emails sent, and for some, I will go as far as having a very good interview but end not receiving the placement. This went on for months. 
      But good thing I had taken up courses on the side so instead of sitting and feeling sorry for myself for all the troubles and worrying about things beyond my control. 
      I poured all my energy, whether positive or negative, into the courses I had taken up. This was my distraction and me in pursuit of a dream of becoming a penetration tester.`,

      `The search for an internship continued for months until there was an opportunity to work with a non-governmental organization. 
      The internship would be about providing security awareness training regarding cyber-crimes against children and women to community centres of the city I lived in. 
      This was promising and exciting. It went as far as almost signing the internship contract. But then I did not hear from the organization anymore. 
      I knew for sure my graduation will be delayed because of how late I would start if I even had the opportunity. 
      This felt like the last straw, and so I did not want to worry about searching for any more opportunities. 
      Hence, I devoted my time to self-development through the courses I had started. 
      Through the process, I had been in contact with my internship coordinator, but when my last opportunity to start my internship also failed, I kept to myself for a while. 
      After enough time that I needed for myself had passed, I reached out to the coordinator to inform him about the recent development and how I should proceed. 
      In one of our meetings, I had told him about my financial situation as an international student and the financial status I have to meet to retain my residency and continue my study.`,

      `Thus, he attempted to provide me with another company within the programme's network and explained my situation to them, and how the lockdown had made my internship search challenging. 
      To my greatest surprise, everything moved so quickly. The company agreed to meet with me, and my interview was scheduled for the next day. 
      The following week, they asked me to start my internship with the company. This was somewhere in February.`,

      `In the same month, I received a job offer through a referral program a friend of mine had in her company. 
      I had taken part in the interview for this job a few months ago and had been awaiting a response. 
      So now I had an internship and job, and I couldn't be happier and more grateful. 
      Though it was challenging at the beginning, it all ended in praise, like my mother would always say.`,
    ],
    category: "Writing",
    image: "writings/office_with_piles_of_textbooks.png",
  },
];
