import React, { useState } from "react";
import { TextField, Button, Snackbar } from "@mui/material";

export default function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // to be replaced with actual API call
      await submitForm({ name, email, message });

      setName("");
      setEmail("");
      setMessage("");

      // display a success message
      setSubmitSuccess(true);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setSubmitError(true);
    }
  };

  const handleCloseSuccessSnackbar = () => {
    setSubmitSuccess(false);
  };

  const handleCloseErrorSnackbar = () => {
    setSubmitError(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        type="email"
        required
      />
      <TextField
        label="Message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        fullWidth
        margin="normal"
        multiline
        rows={4}
        required
      />
      <Button type="submit" variant="contained" color="primary">
        Send Message
      </Button>
      <Snackbar
        open={submitSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccessSnackbar}
        message="Form submitted successfully!"
      />
      <Snackbar
        open={submitError}
        autoHideDuration={6000}
        onClose={handleCloseErrorSnackbar}
        message="Error submitting the form. Please try again later."
      />
    </form>
  );
}

// replace with actual API call
async function submitForm(formData) {
  // mimicing a server request delay
  await new Promise((resolve) => setTimeout(resolve, 2000));
  // will send the formData to server using axios.
}
