import React from "react";
import { useLocation } from "react-router-dom";
import { CardMedia, Container, Typography } from "@mui/material";

import PageNotFound from "../errors/NotFound";

export default function WorkPost() {
  const location = useLocation();
  const { data } = location.state;

  if (!data) {
    return <PageNotFound />;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 20 }} key={data.id}>
      <Typography variant="h2" component="h1" fontSize="3rem" mb={4}>
        {data.title}
      </Typography>
      <CardMedia
        image={require(`../../../assets/${data.image}`)}
        alt={data.title}
        sx={{
          backgroundPosition: "center top",
          height: 486,
          mb: 6,
        }}
      />

      {data.hasOwnProperty("technologies") ? (
        <>
          <Typography
            variant="h6"
            component="h1"
            fontSize="1.5rem"
            sx={{ mt: 2, mb: 2 }}
          >
            Technologies:
          </Typography>
          <Typography
            variant="body2"
            fontSize="1.065rem"
            sx={{ color: "black", mb: 2 }}
          >
            {data.technologies?.map((backend, index) => (
              <Typography
                key={index}
                variant="body1"
                fontSize="1.065rem"
                sx={{ mb: 2 }}
              >
                <span style={{ fontWeight: "bold" }}>Backend:</span>{" "}
                {backend["backend"]?.join(", ")}
              </Typography>
            ))}
            {data.technologies?.map((frontend, index) => (
              <Typography
                key={index}
                variant="body1"
                fontSize="1.065rem"
                sx={{ mb: 2 }}
              >
                <span style={{ fontWeight: "bold" }}>Frontend:</span>{" "}
                {frontend["frontend"]?.join(", ")}
              </Typography>
            ))}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            component="h1"
            fontSize="1.5rem"
            sx={{ mb: 2 }}
          >
            Tools:
          </Typography>
          <Typography variant="body1" fontSize="1.065rem" sx={{ mb: 2 }}>
            {data.tools?.join(", ")}
          </Typography>
        </>
      )}

      <Typography variant="h6" component="h1" fontSize="1.5rem" sx={{ mb: 2 }}>
        Description:
      </Typography>
      {data.description.slice(0).map((description, index) => (
        <Typography
          key={index}
          variant="body1"
          fontSize="1.065rem"
          sx={{ mb: 2 }}
        >
          {description}
        </Typography>
      ))}
      <Typography variant="h6" component="h1" fontSize="1.5rem" sx={{ mb: 2 }}>
        Creative Approach:
      </Typography>
      <Typography variant="body1" fontSize="1.065rem" sx={{ mb: 2 }}>
        {data.creativeApproach}
      </Typography>
      <Typography variant="h6" component="h1" fontSize="1.5rem" sx={{ mb: 2 }}>
        Key Achievements:
      </Typography>
      <ul>
        {data.keyAchievements.slice(1).map((achievement, index) => (
          <li key={index}>
            <Typography variant="body1" fontSize="1.065rem" sx={{ mb: 2 }}>
              {achievement}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography variant="h6" component="h1" fontSize="1.5rem" sx={{ mb: 2 }}>
        Lessons Learned:
      </Typography>
      <Typography variant="body1" fontSize="1.065rem" sx={{ mb: 2 }}>
        {data.lessonsLearned}
      </Typography>
      <Typography variant="h6" component="h1" fontSize="1.5rem" sx={{ mb: 2 }}>
        Conclusion:
      </Typography>
      {data.conclusion.slice(0).map((conclusion, index) => (
        <Typography
          key={index}
          variant="body1"
          fontSize="1.065rem"
          sx={{ mb: 4 }}
        >
          {conclusion}
        </Typography>
      ))}
    </Container>
  );
}
