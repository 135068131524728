import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { Grid, CardMedia, Container, Typography } from "@mui/material";
import styled from "styled-components";

import PageNotFound from "../errors/NotFound";

const PoetryPageWrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
`;

const PoetryText = styled("div")`
  padding-right: 2rem;
`;

const PoetryImage = styled("div")`
  max-width: 100%;
`;

const PoetryCardMedia = styled(CardMedia)`
  height: 1000px;
  min-width: 400px !important;
  margin-top: 150px;

  @media (max-width: 768px) {
  min-width: 270px !important;
  margin-top: 150px;
  },
  
  @media (max-width: 400px) {
  min-width: 270px !important;
  margin-top: 0;
  },
`;

const PoetryTitle = styled(Typography)`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const PoetryContent = styled(Typography)`
  font-size: 1.2rem;
  lineheight: 1.6;
`;

export default function PoetryPage() {
  const location = useLocation();
  const { data } = location.state;

  if (!data) {
    return <PageNotFound />;
  }

  return (
    <div>
      <PoetryPageWrapper maxWidth="md" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <PoetryText>
              <PoetryTitle variant="h2">{data.title}</PoetryTitle>
              {data.content.map((paragraph, paragraphIndex) => (
                <Fragment key={paragraphIndex}>
                  {paragraph.split(/[,.]/).map((line, lineIndex) => (
                    <PoetryContent key={lineIndex} variant="body1">
                      {line.trim()} <br />
                    </PoetryContent>
                  ))}
                </Fragment>
              ))}
            </PoetryText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PoetryImage>
              <PoetryCardMedia
                component="img"
                image={require(`../../../assets/journal/${data.image}`)}
                alt={data.title}
              />
            </PoetryImage>
          </Grid>
        </Grid>
      </PoetryPageWrapper>
    </div>
  );
}
