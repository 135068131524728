import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import {
  Container,
  Grid,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  useMediaQuery,
} from "@material-ui/core";
import { Typography } from "@mui/material";

import { recentWorkData, allWorkData } from "../../../data/portfolioData";

const CardWrapper = styled(Card)`
  max-width: 325px;
  margin: 16px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const CardWrapperRecentWorks = styled(Card)`
  max-width: 325px;
  width: 100%;
  margin: 16px;

  @media (max-width: 1024px) {
    max-width: 249px;
    width: 100%;
  }
`;

const Media = styled(CardMedia)`
  height: 175px;

  @media (max-width: 768px) {
    height: 250px;
  }
`;

const RecentWorkTitle = styled(Typography)`
  font-weight: bold;
`;

const ProjectBody = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",

  "@media (max-width:600px), (max-width:900px)": {
    WebkitLineClamp: 1,
  },
}));

const StyledTypography = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",

  "@media (max-width:600px), (max-width:900px)": {
    WebkitLineClamp: 1,
  },
}));

const renderTechnologies = (stack) => (
  <>
    {stack?.map((tech, index) => (
      <span key={index}>{tech["backend"]?.join(", ")}</span>
    ))}
    {stack?.map((tech, index) => (
      <span key={index}>{tech["frontend"]?.join(", ")}</span>
    ))}
  </>
);

const renderTools = (tools) => <span>{tools.join(", ")}</span>;

const renderTechnologiesSection = (stack) => (
  <StyledTypography variant="body2" color="textSecondary">
    Technologies used: {renderTechnologies(stack)}
  </StyledTypography>
);

const renderToolsSection = (tools) => (
  <StyledTypography variant="body2" color="textSecondary">
    Tools used: {renderTools(tools)}
  </StyledTypography>
);

export default function Works() {
  const isMobile = useMediaQuery("(max-width:600px) or (max-width:900px)");
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  const navigate = useNavigate();

  const openPost = (data) => {
    navigate(`/work/${data.title}`, { state: { data } });
  };

  return (
    <div
      style={{
        marginTop: "73px",
        "@media (max-width: 600px), (max-width: 900px)": { marginTop: "unset" },
      }}
    >
      <Container maxWidth="lg" sx={{ mb: 2 }}>
        <Typography
          variant="h1"
          align="left"
          sx={{
            fontSize: isSmallScreen ? 70 : 85,
            mb: 2,
            ml: !isMobile && 13.7,
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          work
        </Typography>
        {isMobile ? (
          <Box component="main" sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              "The only way to do great work is to love what you do." - Steve
              Jobs
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Explore my works, a testament to my dedication, creativity, and
              technical expertise in coding and ethical hacking. Each one of my
              endeavors represents my commitment to pushing boundaries,
              enhancing my skills, and delivering innovative solutions.
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={10} justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                "The only way to do great work is to love what you do." - Steve
                Jobs
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Explore my works, a testament to my dedication, creativity, and
                technical expertise in coding and ethical hacking. Each one of
                my endeavors represents my commitment to pushing boundaries,
                enhancing my skills, and delivering innovative solutions.
              </Typography>
            </Grid>

            <Grid item xs={4} sx={{ mb: 2 }}>
              <Slider {...settings}>
                {recentWorkData.map((work, index) => (
                  <CardWrapperRecentWorks key={index}>
                    <CardActionArea onClick={() => openPost(work)}>
                      <Media
                        image={require(`../../../assets/${work.image}`)}
                        title={work.title}
                        alt={work.title}
                      />
                      <CardContent>
                        <RecentWorkTitle variant="h5" component="h2">
                          {work.title}
                        </RecentWorkTitle>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="p"
                        >
                          {work.hasOwnProperty("technologies")
                            ? renderTechnologiesSection(work.technologies)
                            : renderToolsSection(work.tools)}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </CardWrapperRecentWorks>
                ))}
              </Slider>
            </Grid>
          </Grid>
        )}
      </Container>
      <Container
        maxWidth="lg"
        style={{ paddingTop: isMobile ? 35 : 100, paddingBottom: 68 }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          {allWorkData.map((project) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={project.id}
              style={{ maxWidth: 345 }}
            >
              <Grid container spacing={4}>
                <CardWrapper>
                  <CardActionArea onClick={() => openPost(project)}>
                    <Media
                      image={require(`../../../assets/${project.image}`)}
                      title={project.title}
                      alt={project.title}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        fontWeight="bold"
                      >
                        {project.title}
                      </Typography>
                      <ProjectBody
                        variant="body1"
                        color="textSecondary"
                        component="p"
                      >
                        {project.description}
                      </ProjectBody>
                      {project.hasOwnProperty("technologies")
                        ? renderTechnologiesSection(project.technologies)
                        : renderToolsSection(project.tools)}
                    </CardContent>
                  </CardActionArea>
                </CardWrapper>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
