import React from "react";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import { learningGoals } from "../skills/LearningGoals";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export default function BarChart() {
  const options = {
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "black",
          font: {
            size: 20,
            weight: "bold",
            family: "Monospace",
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 30,
          color: "black",
          font: {
            size: 20,
            family: "Monospace",
          },
        },
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const labels = learningGoals.map((goal) => goal.name);

  const data = {
    key: [labels],
    labels,
    indexAxis: "x", // horizontal bar chart
    datasets: [
      {
        data: learningGoals.map((goal) => goal.progress),
        barSpacing: 0,
        barPercentage: 1.0, // width of the bars (80% of available space)
        categoryPercentage: 0.9, // width of the category (90% of available space)
        backgroundColor: "rgba(158, 232, 218, 1)",
        borderColor: "rgba(0, 196, 159, 1)",
        borderWidth: 1,
      },
    ],
  };
  return (
    <Box>
      <Bar options={options} data={data} height={115} />
    </Box>
  );
}
