import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Journal from "./pages/Journal";
import SkillsPage from "./components/pages/skills/SkillsPage";
import WorkPost from "./pages/posts/WorkPost";
import WritingsPost from "./pages/posts/WritingsPost";
import PoetryPost from "./pages/posts/PoetryPost";
import ThoughtsPost from "./components/pages/post/ThoughtsPost";

const routes = [
  { path: "/", component: Home, key: "home" },
  { path: "/work", component: Work, key: "work" },
  { path: "/work/:name", component: WorkPost, key: "work-post" },
  { path: "/whoami", component: About, key: "about" },
  { path: "/contact", component: Contact, key: "contact" },
  { path: "/journal", component: Journal, key: "journal" },
  {
    path: "/journal/writings/:name",
    component: WritingsPost,
    key: "writing-post",
  },
  { path: "/journal/poetry/:name", component: PoetryPost, key: "poetry-post" },
  {
    path: "/journal/thoughts/:name",
    component: ThoughtsPost,
    key: "thought-post",
  },
  { path: "/skills", component: SkillsPage, key: "skills-page" },
];

export default routes;
