import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

export const learningGoals = [
  {
    name: "Kubernetes",
    icon: <CloudQueueIcon />,
    progress: 40,
  },
  {
    name: "Ansible",
    icon: <SettingsIcon />,
    progress: 20,
  },
  {
    name: "GoLang",
    icon: <CodeOutlinedIcon />,
    progress: 20,
  },
  {
    name: "Django",
    icon: <SettingsIcon />,
    progress: 65,
  },
  {
    name: "Dutch",
    icon: <RecordVoiceOverIcon />,
    progress: 40,
  },
];
